//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { customTheme } from '../../../configs/theme/theme.config';
//#endregion

interface IProps {
  isMobile?: boolean;
}

export default makeStyles<{}, IProps>(theme =>
  createStyles({
    root: {
      width: ({ isMobile }) => isMobile ? 'unset' : 745,
      margin: ({ isMobile }) => isMobile ? '0 20px' : '0 auto',
      border: `1px solid ${ customTheme.colors.grey }`,
      borderRadius: 15,
      boxShadow: '0px 6px 4px rgba(0, 0, 0, 0.12)'
    }
  }),
  { name: 'Wrapper' }
);

//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createTheme';

import { customTheme } from '../../../configs/theme/theme.config';
//#endregion

interface IProps {
  isMobile?: boolean;
  isTablet?: boolean;
}

export default makeStyles<Theme, IProps>(theme =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      minHeight: 136,
      boxShadow: '0px 6px 4px rgba(0, 0, 0, 0.12)'
    },
    container: {
      width: '90vw',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down(1150)]: {
        width: '95vw'
      }
    },
    navLink: {
      fontFamily: customTheme.fontFamily.interBold,
      color: customTheme.colors.blue,
      textDecoration: 'none',
      fontSize: 16,
      [theme.breakpoints.down(1200)]: {
        fontSize: 14
      }
    },
    locale: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 50,
      height: 50,
      color: customTheme.colors.blue,
      borderRadius: '50%',
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
      fontFamily: customTheme.fontFamily.interBold,
      textTransform: 'uppercase'
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    logoText: {
      marginLeft: 20,
      fontSize: 20,
      color: customTheme.colors.blue
    }
  }),
  { name: 'MenuDesktop' }
);

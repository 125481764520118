//#region imports
import React from 'react';
import { useTranslation } from 'react-i18next';

import useMobile from '../../../hooks/use.mobile.hook';

import Button from '../../../components/shared/Button';
import Wrapper from '../../../components/shared/Wrapper';

import i18n from '../../../i18n';

import stylesRules from './styles';
//#endregion

const Rules = () => {
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = stylesRules({ isMobile });

  const rulesList = [...Array(5)].map((el, i) => {
    return (
      <div className={ classes.item } key={ i }>
        <span className={ `${ classes.icon } ${ i === 4 ? classes.iconReverse : '' }` }>{ i !== 4 ? i + 1 : '!' }</span>
        <span>{ t(`rules.rule${i}`) }</span>
      </div>
    );
  });

  return (
    <>
      { isMobile && <div className={ classes.picture }/> }
      <Wrapper className={ classes.root }>
        <h3 className={ classes.header } id="rules">{ t('rules.header') }</h3>
        <section className={ classes.container }>
          { rulesList }
          <div className={ classes.button }>
            <Button
              title={ t('rules.button') }
              isMobile={ isMobile }
              link={ `/rules_${i18n.language}.pdf` }
            />
          </div>
        </section>
      </Wrapper>
    </>
  );
};

export default Rules;

//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';

import { customTheme } from '../../../configs/theme/theme.config';
import bannerImg from '../../../assets/img/banner.webp';
//#endregion

interface IProps {
  isMobile?: boolean;
}

export default makeStyles<Theme, IProps>(theme =>
  createStyles({
    root: {
      margin: ({ isMobile }) => isMobile ? '0 20px 20px 20px' : 0,
      borderRadius: ({ isMobile }) => isMobile ? 15 : 0,
      background: ({ isMobile }) =>
        isMobile
          ? `${ customTheme.colors.yellow } right -105px bottom 47px / 274px 194px no-repeat url(${bannerImg})`
          : customTheme.colors.yellow,
      boxShadow: ({ isMobile }) => isMobile ? '0px 6px 4px rgba(0, 0, 0, 0.12)' : 'none',
      [theme.breakpoints.down(355)]: {
        backgroundPosition: 'right -140px center !important'
      }
    },
    container: {},
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: ({ isMobile }) => isMobile ? 'flex-start' : 'center',
      padding: ({ isMobile }) => isMobile ? '20px 0 35px' : '30px 0'
    },
    picture: {
      maxHeight: 404
    },
    text: {
      order: ({ isMobile }) => isMobile ? 1 : 2,
      flexBasis: '70%',
      maxWidth: ({ isMobile }) => isMobile ? 'unset' : 500,
      marginLeft: 22,
      [theme.breakpoints.down(1040)]: {
        flexBasis: '60%'
      },
      [theme.breakpoints.down(480)]: {
        flexBasis: '55%'
      },
      [theme.breakpoints.down(430)]: {
        flexBasis: '50%'
      }
    },
    header: {
      marginBottom: ({ isMobile }) => isMobile ? 0 : 20,
      padding: ({ isMobile }) => isMobile ? '35px 22px 0px' : 0,
      fontSize: ({ isMobile }) => isMobile ? 21 : 40,
      lineHeight: ({ isMobile }) => isMobile ? '26px' : '54px',
      fontFamily: customTheme.fontFamily.interBold
    },
    desc: {
      fontSize: ({ isMobile }) => isMobile ? 11 : 16,
      lineHeight: ({ isMobile }) => isMobile ? '14px' : '25px',
      '& b': {
        fontFamily: customTheme.fontFamily.interBold
      }
    },
    buttons: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: ({ isMobile }) => isMobile ? 'column' : 'row',
      maxWidth: ({ isMobile }) => isMobile ? 130 : 'unset',
      '& button .MuiButton-label': {
        padding: '0 10px',
        textWrap: 'nowrap',
        [theme.breakpoints.down(420)]: {
          padding: 0
        },
        [theme.breakpoints.down(375)]: {
          fontSize: 16
        }
      }
    },
    button: {
      '&.MuiButtonBase-root': {
        margin: ({ isMobile }) => isMobile ? '17px 0 0 0' : '30px 20px 0 0'
      }
    }
  }),
  { name: 'Banner' }
);

//#region imports
import React from 'react';
import { useTranslation } from 'react-i18next';

import stylesCard from '../styles';
import useMobile from '../../../../hooks/use.mobile.hook';
import question from '../../../..//assets/img/question.webp';
//#endregion

const CardFeedback = () => {
  const { isMobile } = useMobile();
  const classes = stylesCard({ isMobile });
  const { t } = useTranslation();

  return (
    <span className={ classes.container } id="feedback">
      <img className={ classes.icon } src={ question }/>
      <span className={ classes.desc } dangerouslySetInnerHTML={ { __html: t('card.feedback') } }/>
    </span>
  );
};

export default CardFeedback;

//#region imports
import React from 'react';
import { useTranslation } from 'react-i18next';

import stylesCard from './styles';
import useMobile from '../../../hooks/use.mobile.hook';

import CardDesc from './CardDesc';
import CardFeedback from './CardFeedback';
//#endregion

const Card = () => {
  const { isMobile } = useMobile();
  const classes = stylesCard({ isMobile });
  const { t } = useTranslation();

  return (
    <div className={ classes.wrapper }>
      <CardDesc />
      <CardFeedback />
    </div>
  );
};

export default Card;

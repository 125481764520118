//#region imports
import React, { FC } from 'react';
import ButtonUi from '@material-ui/core/Button';

import isFunction from 'lodash/isFunction';

import stylesButton from './styles';

import { IButtonProps, EButtonVariant } from '../../../components/shared/Button/index';
//#endregion

const Button: FC<IButtonProps> = (
  { title,
    isMobile,
    className,
    link,
    type,
    variant = EButtonVariant.primary,
    onClick
  }) => {
  const classes = stylesButton({ isMobile });

  const handleModal = () => {
    if (link) {
      window.open(link, '_blank', 'noopener');
    } else isFunction(onClick) && onClick();
  };

  return (
    <ButtonUi className={ `${className} ${classes.button} ${classes[variant]}` } onClick={ handleModal }>
      { title }
    </ButtonUi>
  );
};

export default Button;

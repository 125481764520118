//#region imports
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ru from './locales/ru';
import kz from './locales/kz';
import initStore from '../entities/store';
//#endregion

const store = initStore();
const state = store.getState();

i18n
  .use(initReactI18next)
  .init({
    lng: state.locale.currentLocale,
    fallbackLng: 'ru',
    resources: {
      ru: {
        translations: { ...ru }
      },
      kz: {
        translations: { ...kz }
      }
    },
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

const ru = {
  action: {
    participate: 'Участвовать',
    disable: 'Отключить',
    support: 'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a>'
  },
  menu: {
    logo: 'Национальная<br />лотерея',
    participate: 'Участвовать в лотерее',
    prizes: 'Призы',
    rules: 'Правила лотереи',
    feedback: 'Обратная связь',
    disable: 'Отключить лотерею',
    localeKz: 'kz',
    localeRu: 'ру',
    menu: 'Меню'
  },
  common: {
    titleApp: 'Национальная лотерея'
  },
  banner: {
    header: 'Выиграйте миллион в лотерее «Твоя Удача»',
    label: 'Моментальная лотерея для абонентов <b>TELE2</b> и <b>ALTEL</b>!',
    desc: 'Получайте билеты каждый день, выигрывайте и станьте новым миллионером!'
  },
  promo: {
    header: 'Участвуйте каждый день',
    desc: 'Оформите подписку на лотерею «Удача» и получайте по одному лотерейному билету в SMS каждый день.',
    appeal: 'Чем больше билетов, тем выше шанс выиграть миллион!'
  },
  prizes: {
    header: 'Призы',
    label: 'Призовой фонд лотереи «Твоя Удача»:',
    prize0: '2 648 176 выигрышей по 100 ₸',
    prize1: '2 575 250 выигрышей по 200 ₸',
    prize2: '463 421 выигрышей по 400 ₸',
    prize3: '71 258 выигрышей по 1 000 ₸',
    prize4: '12 021 выигрышей по 2 000 ₸',
    prize5: '4 176 выигрышей по 4 000 ₸',
    superPrizeLabel: '3 супер-приза',
    superPrize: '1 000 000 ₸',
    buttonSubscribe: 'Участвовать',
    buttonUnsubscribe: 'Отключить лотерею'
  },
  cards: {
    desc1: 'Лотерея «Удача» разработана национальной лотереей\n' +
      'Республики Казахстан АО «Сәтті Жұлдыз»',
    desc2: 'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер \n' +
      '<a href="tel:8-800-080-9351">8 800 080 93 51</a>'
  },
  rules: {
    header: 'Правила лотереи',
    rule0: 'Каждый день вы получаете один лотерейный билет.',
    rule1: 'Билет разыгрывается моментально, и в случае выигрыша вам сразу же приходит сообщение.',
    rule2: 'Если ваш билет выиграл, денежный приз будет мгновенно зачислен на ваш абонентский счет. Если вы выиграете суперприз, мы свяжемся с вами для перевода выигрыша.',
    rule3: 'Если ваш билет не выиграл - не расстраивайтесь! Вы можете попытать удачу и докупить еще один лотерейный билет каждый день.',
    rule4: 'Участвуйте в лотерее каждый день, чтобы увеличить свои шансы выиграть миллион!',
    button: 'Все правила лотереи'
  },
  winners: {
    title: 'Победители лотереи',
    winner0: {
      name: 'Анвар Т.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    },
    winner1: {
      name: 'Нурлан С.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    },
    winner2: {
      name: 'Анвар Т.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    }
  },
  modal: {
    subscribe: {
      title: 'Участвуйте в лотерее',
      desc: 'Получайте лотерейные билеты каждый день и выиграйте миллион!<br /><br />' +
        'Для участия в лотерее «Твоя Удача» у вас должна быть оформлена подписка, стоимость 100 тенге/день.<br /><br />' +
        '<b>Для регистрации и активации подписки</b> наберите на клавиатуре своего мобильного телефона USSD <a href="tel:*88%23">*88#</a><br /><br />' +
        '<b>Чтобы купить еще один или несколько билетов</b>, наберите *88*N#, где N количество билетов от 1 до 10. Например, чтобы купить 10 билетов, наберите <a href="tel:*88*10%23">*88*10#</a>' +
        ' Стоимость каждого купленного билета 100 ₸.<br /><br />' +
        'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер ' +
        '<a href="tel:8-800-080-9351">8-800-080-9351</a>',
      button: 'Хорошо!'
    },
    unsubscribe: {
      title: 'Управление подпиской',
      desc: 'Продолжая участвовать в лотерее с ежедневными билетами вы получаете шанс выиграть миллион тенге!<br /><br />' +
        'Для отключения подписки на лотерею наберите на мобильном телефонe USSD <a href="tel:*88*0%23">*88*0#</a> ' +
        'или позвоните на горячую линию <a href="tel:8-800-080-9351">8-800-080-9351</a>',
      button: 'Хорошо!'
    }
  },
  card: {
    desc: 'Лотерея «Твоя Удача» разработана с национальной лотереей Республики Казахстан АО «Сәтті Жұлдыз»',
    feedback: 'Для получения подробной информации или решения возникшего вопроса позвоните на бесплатный номер \n' +
      '<a href="tel:8-800-080-9351">8 800 080 93 51</a>'
  },
  footer: {
    copyright: `${new Date().getFullYear()} © АО «Сәтті Жұлдыз»`,
    feedback: 'Обратная связь',
    terms: 'Пользовательское соглашение'
  }
};

export default ru;

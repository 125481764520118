//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { customTheme } from '../../../configs/theme/theme.config';
//#endregion

interface IProps {
  isMobile?: boolean;
}

export default makeStyles<{}, IProps>(theme =>
  createStyles({
    wrapperMobile: {
      '& .MuiDrawer-paper': {
        width: 'calc(100vw - 60px)',
        padding: 30,
        backgroundColor: customTheme.colors.white
      }
    },
    wrapperDesktop: {
      '& .MuiPaper-rounded': {
        borderRadius: 15
      },
      '& .MuiDialog-paper': {
        maxWidth: 560,
        padding: '40px 40px 50px 40px'
      }
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    title: {
      paddingBottom: 30,
      fontSize: ({ isMobile }) => isMobile ? 24 : 32,
      fontFamily: customTheme.fontFamily.interBold,
      color: customTheme.colors.blue
    },
    close: {
      width: ({ isMobile }) => isMobile ? 20 : 27,
      height: ({ isMobile }) => isMobile ? 20 : 27,
      cursor: 'pointer'
    },
    desc: {
      fontSize: ({ isMobile }) => isMobile ? 14 : 16,
      paddingRight: ({ isMobile }) => isMobile ? 0 : 25,
      lineHeight: '22px',
      '& a': {
        fontFamily: customTheme.fontFamily.interBold,
        color: customTheme.colors.blue,
        textDecoration: 'underline',
        textWrap: 'nowrap'
      },
      '& b': {
        fontFamily: customTheme.fontFamily.interBold
      }
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 30
    }
  }),
  { name: 'Modal' }
);

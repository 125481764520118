//#region imports
import React, { FC } from 'react';

import stylesWrapper from './styles';
import useMobile from '../../../hooks/use.mobile.hook';
import { IWrapperProps } from './types';
//#endregion

const Wrapper: FC<IWrapperProps> = ({ children, className }) => {
  const { isMobile } = useMobile();
  const classes = stylesWrapper({ isMobile });

  return (
    <div className={ `${className} ${classes.root}` }>{ children }</div>
  );
};

export default Wrapper;

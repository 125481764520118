//#region imports
import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

import { LOCALE_ACTIONS } from './locale.actions';
import { ILocaleInitialState } from './locale.model';
//#endregion

const initialState: ILocaleInitialState = {
  locales: ['kz', 'ru'],
  currentLocale: 'ru'
};

export type TLocaleState = Readonly<typeof initialState>;

export default (state: TLocaleState = initialState, action: { type: any; payload: any; }): TLocaleState => {
  const { type, payload } = action;

  switch (type) {
    case LOCALE_ACTIONS.CHANGE_LOCALE:
      return flow([
        set('currentLocale', payload)
      ])(state);

    default:
      return state;
  }
};

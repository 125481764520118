//#region imports
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import '../../assets/css/reset.css';
import useMobile from '../../hooks/use.mobile.hook';
import Banner from '../../components/shared/Banner';
import Prizes from '../../components/shared/Prizes';
import Rules from '../../components/shared/Rules';
import Footer from '../../components/shared/Footer';
import CardDesc from '../../components/shared/Card/CardDesc';
import CardFeedback from '../../components/shared/Card/CardFeedback';
import Card from '../../components/shared/Card';
//#endregion

const MainPage = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();

  useEffect(() => {
    document.title = t('common.titleApp');
  }, []);

  return (
    <>
      <Banner />
      <Prizes />
      { isMobile ? <CardDesc /> : <Card/> }
      <Rules />
      { isMobile && <CardFeedback /> }
      <Footer />
    </>
  );
};

export default MainPage;

//#region imports
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './assets/css/reset.css';
import './assets/css/font.styles.css';
import './assets/css/base.styles.css';

import MainPage from './pages/Main';
import Layout from './templates/Layout';
import ModalMobile from './components/mobile/Modal';
import ModalDesktop from './components/desktop/Modal';
import useMobile from './hooks/use.mobile.hook';
//#endregion

const App = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();

  useEffect(() => {
    document.title = t('common.titleApp');
  }, []);

  return (
    <>
      { isMobile ? <ModalMobile /> : <ModalDesktop /> }
      <Layout>
        <MainPage />
      </Layout>
    </>
  );
};

export default App;

//#region imports
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { customTheme } from '../../../configs/theme/theme.config';
import coins from '../../../assets/img/coins.webp';
import prizeImg from '../../../assets/img/prize.webp';
//#endregion

interface IProps {
  isMobile?: boolean;
}

export default makeStyles<{}, IProps>(theme =>
  createStyles({
    root: {
      margin: ({ isMobile }) => isMobile ? '20px 0' : '30px 0 0 0'
    },
    container: {
      padding: ({ isMobile }) => isMobile ? '22px' : '38px 40px'
    },
    wrapper: {
      background: ({ isMobile }) => isMobile ? 'none' : `right / contain no-repeat url(${coins})`
    },
    picture: {
      width: ({ isMobile }) => isMobile ? 'unset' : 745,
      height: ({ isMobile }) => isMobile ? '60vw' : 427,
      maxHeight: 427,
      margin: '0 auto',
      background: `bottom / contain no-repeat url(${prizeImg})`
    },
    header: {
      height: ({ isMobile }) => isMobile ? 72 : 96,
      padding: ({ isMobile }) => isMobile ? '0 22px' : '0 40px',
      color: customTheme.colors.white,
      backgroundColor: customTheme.colors.yellow,
      fontSize: ({ isMobile }) => isMobile ? 22 : 32,
      fontFamily: customTheme.fontFamily.interBold,
      lineHeight: ({ isMobile }) => isMobile ? '72px' : '96px',
      borderRadius: '15px 15px 0 0'
    },
    label: {
      marginBottom: ({ isMobile }) => isMobile ? 15 : 20,
      fontFamily: customTheme.fontFamily.interBold,
      fontSize: ({ isMobile }) => isMobile ? 14 : 16
    },
    icon: {
      marginRight: 15
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 15,
      fontSize: ({ isMobile }) => isMobile ? 14 : 16
    },
    superPrizeContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      margin: ({ isMobile }) => isMobile ? '20px 0 0 -3px' : '30px 0 0 -7px'
    },
    superPrize: {
      height: ({ isMobile }) => isMobile ? 50 : 56
    },
    textBold: {
      marginBottom: ({ isMobile }) => isMobile ? 10 : 10,
      fontSize: ({ isMobile }) => isMobile ? 14 : 16,
      fontFamily: customTheme.fontFamily.interBold
    },
    amount: {
      fontSize: ({ isMobile }) => isMobile ? 24 : 32,
      fontFamily: customTheme.fontFamily.interBold,
      color: customTheme.colors.blue
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: ({ isMobile }) => isMobile ? 87 : 123,
      maxWidth: ({ isMobile }) => isMobile ? 219 : 229,
      margin: ({ isMobile }) => isMobile ? '36px auto 20px auto' : '45px auto 10px auto',
      '& button .MuiButton-label': {
        padding: '0 10px'
      }
    }
  }),
  { name: 'Prizes' }
);

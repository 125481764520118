//#region imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { StoreContext } from 'redux-react-hook';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core';

import { customTheme } from './configs/theme/theme.config';
import i18n from './i18n';
import initStore from './entities/store';
import App from './app';
//#endregion

const store = initStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Router>
    <Provider store={ store } >
      <StoreContext.Provider value={ store }>
        <I18nextProvider i18n={ i18n }>
          <ThemeProvider theme={ customTheme }>
            <App />
          </ThemeProvider>
        </I18nextProvider>
      </StoreContext.Provider>
    </Provider>
  </Router>
);

//#region imports
import React from 'react';
import { useTranslation } from 'react-i18next';

import stylesFooter from './styles';
import useMobile from '../../../hooks/use.mobile.hook';
//#endregion

const Footer = () => {
  const { isMobile } = useMobile();
  const classes = stylesFooter({ isMobile });
  const { t } = useTranslation();

  return (
    <div className={ classes.wrapper }>
      <div className={ classes.item }>{ t('footer.copyright') }</div>
      <a className={ classes.item } href = "mailto:lottery@cmid.kz">
        { t('footer.feedback') }
      </a>
      <a className={ classes.item } href={ '/oferta.pdf' } target={ '_blank' } rel={ 'noopener' }>
        { t('footer.terms') }
      </a>
    </div>
  );
};

export default Footer;

//#region imports
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import flow from 'lodash/fp/flow';

import MenuDesktop from '../../components/desktop/Menu';
import MenuMobile from '../../components/mobile/Menu';
import useMobile from '../../hooks/use.mobile.hook';

import { ModalService, TModalServiceProps } from '../../services/modal.service';
//#endregion

interface ILayoutProps {
  children: ReactNode;
  modalService: TModalServiceProps;
}

const Layout: FC<ILayoutProps> = ({ children, modalService }) => {
  const { actions } = modalService;
  const { isMobile } = useMobile();
  const { t } = useTranslation();

  const menuItems: {
    number: number;
    title: string;
    link?: string;
    action?: () => void;
  }[] = [
    { number: 1, title: t('menu.participate'), link: '#participate' },
    { number: 2, title: t('menu.prizes'), link: '#prizes' },
    { number: 3, title: t('menu.rules'), link: '#rules' },
    { number: 4, title: t('menu.feedback'), link: '#feedback' },
    { number: 5, title: t('menu.disable'), link: '', action: actions.toggleModalUnsubscribe }
  ];

  return (
    <>
      { isMobile ? <MenuMobile items={ menuItems } /> : <MenuDesktop items={ menuItems }/> }
      { children }
    </>
  );
};

export default flow([ModalService])(Layout);

import { EModalTypes } from '../../../models/types';

export enum EButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  outlined = 'outlined'
}

export interface IButtonProps {
  title: string;
  isMobile: boolean;
  className?: string;
  link?: string;
  type?: EModalTypes;
  variant?: EButtonVariant;
  onClick?: () => void;
}

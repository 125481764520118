//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import stylesModal from './styles';
import useMobile from '../../../hooks/use.mobile.hook';
import closeDark from '../../../assets/svg/close.dark.svg';
import Button from '../../../components/shared/Button';
//#endregion

interface IModalContentProps {
  toggleModal: () => void;
}

const ModalSubscribeContent: FC<IModalContentProps> = ({ toggleModal }) => {
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = stylesModal({ isMobile });

  return (
    <>
      <div className={ classes.header }>
        <h3 className={ classes.title }>{ t('modal.unsubscribe.title') }</h3>
        <img className={ classes.close } src={ closeDark } onClick={ toggleModal }/>
      </div>
      <div className={ classes.desc } dangerouslySetInnerHTML={ { __html: t('modal.unsubscribe.desc') } }/>
      <div className={ classes.button }>
        <Button
          title={ t('modal.unsubscribe.button') }
          isMobile={ isMobile }
          onClick={ toggleModal }
        />
      </div>
    </>
  );
};

export default ModalSubscribeContent;

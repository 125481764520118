//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import flow from 'lodash/fp/flow';

import useMobile from '../../../hooks/use.mobile.hook';
import stylesBanner from './styles';
import bannerImg from '../../../assets/img/banner.webp';
import Button, { EButtonVariant } from '../../../components/shared/Button';

import { ModalService, TModalServiceProps } from '../../../services/modal.service';
//#endregion

interface IBannerProps {
  modalService: TModalServiceProps;
}

const Banner: FC<IBannerProps> = ({ modalService }) => {
  const { actions } = modalService;
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const classes = stylesBanner({ isMobile });

  return (
    <section className={ classes.root }>
      <div className={ classes.container }>
        { isMobile && <h1 className={ classes.header }>{ t('banner.header') }</h1> }
        <div className={ classes.content }>
          { !isMobile && <img className={ classes.picture } src={ bannerImg } alt={ t('banner.header') }/> }
          <div className={ classes.text }>
            { !isMobile && <h1 className={ classes.header }>{ t('banner.header') }</h1> }
            <h4 dangerouslySetInnerHTML={ { __html: t('banner.label') } } className={ classes.desc }/><br />
            <span className={ classes.desc }>{ t('banner.desc') }</span><br />
            <div className={ classes.buttons }>
              <Button
                className={ classes.button }
                title={ t('action.participate') }
                isMobile={ isMobile }
                onClick={ actions.toggleModalSubscribe }
              />
              <Button
                variant={ EButtonVariant.secondary }
                className={ classes.button }
                title={ t('action.disable') }
                isMobile={ isMobile }
                onClick={ actions.toggleModalUnsubscribe }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default flow([ModalService])(Banner);
